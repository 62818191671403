import { Service } from "../../config/service";

class Status extends Service {

  constructor() {
    super("status")
  }

}

export default Status

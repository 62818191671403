import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from "highcharts";
import ExportingHighcharts from "highcharts/modules/exporting";
import ExportingDataHighcharts from "highcharts/modules/export-data";
import AnnotationsHighcharts from "highcharts/modules/annotations";
import StockInit from 'highcharts/modules/stock'
ExportingHighcharts(Highcharts);
ExportingDataHighcharts(Highcharts);
AnnotationsHighcharts(Highcharts);
StockInit(Highcharts);

Vue.use(HighchartsVue)

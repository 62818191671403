import { Service } from "../../config/service";

class CheckTudoService extends Service {

  constructor(placa) {
    super("checktudo", placa)
  }

}

export default CheckTudoService
import axios from 'axios'

import interceptorRequest from './interceptor-request.js'
import interceptorResponse from './interceptor-response.js'

const baseUrl = process.env.VUE_APP_HTTP_ROOT_API || 'https://api.pitom.com.br/api/v1'
const timeout = process.env.VUE_APP_HTTP_TIMEOUT || '60000'
const headers = {
  'Access-Control-Allow-Origin': '*',
  //"User-Agent": process.env.VUE_APP_HTTP_USER_AGENT,
  'Content-Type': process.env.VUE_APP_HTTP_CONTENT_TYPE || 'application/json',
  'Accept': process.env.VUE_APP_HTTP_ACCEPT || '*/*',
}

class Http {
  constructor() {
    this.httpInstance = axios.create()
  }

  httpInstance() {
    return this.httpInstance
  }

  getInstance() {
    return this.httpInstance
  }

  //#region Configurations
  setBaseURL(baseURL) {
    this.httpInstance.defaults.baseURL = baseURL
  }
  
  setTimeout(timeout) {
    this.httpInstance.defaults.timeout = timeout
  }

  setHeaders(headers) {
    this.httpInstance.defaults.headers = headers
  }

  setBearerToken(token) {
    this.httpInstance.defaults.headers['Authorization'] = `Bearer ${token}`
  }
  //#endregion
  
  //#region Http Methods
  get(url, config = {}) {
    return this.httpInstance.get(url, config)
  }

  post(url, data = {}, config = {}) {
    return this.httpInstance.post(url, data, config)
  }

  put(url, data = {}, config = {}) {
    return this.httpInstance.put(url, data, config)
  }

  patch(url, data = {}, config = {}) {
    return this.httpInstance.patch(url, data, config)
  }

  delete(url, config = {}) {
    return this.httpInstance.delete(url, config)
  }
  //#endregion

  //#region Interceptors
  useInterceptors() {
    this.httpInstance.interceptors.request.use(
      interceptorRequest.config,
      interceptorRequest.error
    )

    this.httpInstance.interceptors.response.use(
      interceptorResponse.response,
      interceptorResponse.error
    )
  }
  //#endregion
}

const http = new Http()
http.setBaseURL(baseUrl)
http.setTimeout(timeout)
http.setHeaders(headers)

export default http

import { Service } from "../../../config/service";

class PluginFipeModelService extends Service {

  constructor(brand) {
    super(`plugin/fipe/model/${brand}`)
  }

}

export default PluginFipeModelService

<template>
  <div>
    <component :is="resolveLayout">
      <!-- <keep-alive :include="routesNameKeepAlives.join(',')"> -->
      <router-view></router-view>
      <!-- </keep-alive> -->
    </component>

    <TheLoading></TheLoading>
  </div>
</template>

<script>
import BlankLayout from "@pitom84/layouts/src/Blank.vue";
import ContentLayout from "@pitom84/layouts/src/Content.vue";
import defineRulesFor from "@/plugins/casl/ability";
import TheLoading from "./components/global/the-loading/TheLoading.vue";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { httpsCallable, getFunctions } from "firebase/functions";
import { NotificationUrls } from "@/helpers/notification-urls";

export default {
  name: "App",

  data() {
    return {
      // routesNameKeepAlives: ['Avaliacoes', 'Avaliações', 'Repasse'],
      scrollPositions: {},
    };
  },

  components: {
    BlankLayout,
    ContentLayout,
    TheLoading,
  },

  computed: {
    resolveLayout() {
      if (this.$route.name === null) return null;
      if (this.$route.meta.layout === "blank") return "blank-layout";
      return "content-layout";
    },
  },

  // watch: {
  //   $route(to, from) {
  //     if (this.routesNameKeepAlives.includes(from.name)) {
  //       this.scrollPositions[from.name] = window.scrollY
  //     }

  //     if (this.routesNameKeepAlives.includes(to.name)) {
  //       this.$nextTick(() => {
  //         window.scrollTo(0, this.scrollPositions[to.name] || 0)
  //       })
  //     }
  //   }
  // },

  methods: {
    checkTheme() {
      this.$vuetify.theme.dark =
        localStorage.getItem("app-theme-dark") === "true";
    },

    async createFCMToken() {
      try {
        const messaging = getMessaging();
        const token = await getToken(messaging, {
          vapidKey: process.env.VUE_APP_FCM_VAPID_KEY,
        });

        if (token && Notification.permission === "granted") {
          const functions = getFunctions();
          const CallP84ApiUser = httpsCallable(functions, "CallP84ApiUser");
          await CallP84ApiUser({
            fcm_token: {
              token,
              origin: "web",
              platform: "web",
            },
          });
        }
      } catch (error) {
        console.error("An error occurred createFCMToken:", error);
      }
    },

    registerMessageListener() {
      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        if (document.visibilityState !== "visible") return;

        const notificationTitle = payload.data.title;
        const notificationType = payload.data.notification_type;
        const appraisalId = payload.data.appraisal_id;
        const url = NotificationUrls[notificationType] + `/${appraisalId}`;

        const notificationOptions = {
          body: payload.data.body,
          icon: "/favicon.png",
          data: {
            url,
          },
        };

        new Notification(
          notificationTitle,
          notificationOptions
        ).addEventListener("click", (e) => {
          window.open(e.target.data.url, "_blank");
        });

        this.$toast.default(notificationTitle, {
          message: notificationOptions.body,
          dismissible: true,
          duration: 5000,
          queue: true,
          onClick: () => {
            window.open(url, "_blank");
          },
        });
      });
    },

    onMounted() {
      this.checkTheme();

      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        this.$ability.update(defineRulesFor(user.user_roles[0].role.name));
        this.createFCMToken();
        this.registerMessageListener();
      }
    },
  },

  mounted() {
    this.onMounted();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/app.scss";
</style>

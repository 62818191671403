import Authentication from "@pitom84/integrations-services/src/classes/authentication/Authentication"
import { Service } from "../../config/service";
import UserService from "../users";

class AuthService extends Service {
  constructor() {
    super("login/user")
  }

  async login(email, password) {
    try {
      await Authentication.signInWithEmailAndPassword(email, password)
      if(!Authentication.emailIsVerified()) {
        Authentication.sendEmailVerification()
        throw new Error("Seu e-mail ainda não foi validado, encaminhamos uma mensagem no seu e-mail para validar.")
      }
      const currentUser = Authentication.getCurrentUser()
      const { data } = await new UserService().getByIdOrEmail(currentUser.uid, currentUser.email)
      return data
    } catch(error) {
      this.setError(error)
      throw error
    }
  }

  async forgotPassword(email) {
    try {
      await Authentication.sendPasswordResetEmail(email)
    } catch(error) {
      this.setError(error)
      throw error
    }
  }

  async getStoreList(hash) {
    try {
      const url = `${
        this.getResource().root
      }/getStores/${hash}`;
      const response = this.http.get(url);
      return response;
    } catch(error) {
      this.setError(error);
      throw error
    }
  }

  async signOut() {
    await Authentication.signOut()
  }
}

export default AuthService
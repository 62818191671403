import { Service } from "../../config/service";

class Sellers extends Service {

  constructor() {
    super("sellers")
  }

}

export default Sellers

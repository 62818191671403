import { Service } from "../../config/service";

class MessageTypesService extends Service {

  constructor() {
    super("message-types")
  }

}

export default MessageTypesService

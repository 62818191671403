<template>
  <div>
    <v-menu
      v-model="isOpenMenu"
      :close-on-content-click="false"
      :close-on-click="true"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-btn v-bind="attrs" :block="block" :color="color" v-on="on">
          <v-icon left v-if="!caption">mdi-calendar</v-icon>
          <div v-if="!caption">{{ formatDate(value[0]) }} - {{ formatDate(value[1]) }}</div>
          <div v-if="caption">{{ caption }}</div>
        </v-btn>
      </template>
      <v-card>
        <v-date-picker v-model="dates" range no-title locale="pt-br">
        </v-date-picker>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onClickApply()">
            Aplicar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "RangeDate",

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    caption: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "secondary"
    },
    block: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isOpenMenu: false,
      dates: this.value,
    };
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    onClickApply() {

      if(this.dates.length === 1){
        this.dates.push(this.dates[0])
      }

      const date1 = this.dates[0];
      const date2 = this.dates[1];
      
      if(date1 > date2) {
        this.dates[0] = date2;
        this.dates[1] = date1;
      }

      this.$emit("input", this.dates);
      this.$emit("click");
      this.isOpenMenu = false;
    },
  },
};
</script>

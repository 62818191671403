<template>
  <div>
    <NoImageSelected
      v-show="!file"
      :title="title"
      :background-image="backgroundImage"
      :width="width"
      :height="height"
      :error-messages="errorMessages"
      @change="onChange($event)"
    ></NoImageSelected>

    <ImageSelected
      v-show="file"
      :title="title"
      :image="fileUrl"
      :width="width"
      :height="height"
      @click:remove="removeFile()"
      @change="onChange($event)"
    ></ImageSelected>
  </div>
</template>

<script>
export default {
  name: 'DragDropImage',

  components: {
    NoImageSelected: () => import('./components/NoImageSelected.vue'),
    ImageSelected: () => import('./components/ImageSelected.vue')
  },

  props: {
    value: {
      required: false
    },
    title: {
      required: false
    },
    width: {
      default: '100%'
    },
    height: {
      default: '300px'
    },
    errorMessages: {
      type: Array,
      required: false
    },
    backgroundImage: {
      required: false,
      default: () => require('@/assets/images/templates/no-image.png')
    },
  },

  data() {
    return {
      file: null,
      fileUrl: null,

      gradient: 'to top right, rgba(100,115,201,.35), rgba(25,32,72,.5)'
    }
  },

  methods: {
    selectedFile(file) {
      this.file = file
      this.fileUrl = URL.createObjectURL(file)
      this.$emit('input', {
        file: this.file,
        fileUrl: this.fileUrl
      })
    },

    removeFile() {
      this.file = null
      this.fileUrl = null
      this.$emit('input', null)
    },

    onChange(event) {
      this.selectedFile(event)
    }
  },

  watch: {
    value(v) {
      if(!v) {
        this.file = null;
      }
    }
  }
}
</script>

<style lang="scss">
@import './scss/drag-drop-image.scss';
</style>

import { Service } from "../../config/service";

class FipeModelsService extends Service {

  constructor() {
    super("fipe/models")
  }

}

export default FipeModelsService

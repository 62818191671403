<template>
  <v-container>
    <v-alert v-show="$can('no-access')" shaped prominent type="info">
      <v-row>
        <v-col class="grow">
          <v-row>
            <v-col cols="12">
              Carregando permissões do usuário.
            </v-col>
            <v-col cols="12">
              <v-progress-linear color="success" indeterminate rounded height="12"></v-progress-linear>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="shrink">
          <v-btn :to="{ name: 'Logout' }">
            <v-icon left>mdi-logout</v-icon>
            Sair
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>

<script>
export default {
  name: 'NoAccess'
}
</script>

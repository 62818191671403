import { Service } from "../../config/service";

class StorageService extends Service {
  constructor() {
    super("storage");
  }

  async uploadFile(file, fileName, folder, metadata) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", fileName);
      formData.append("folder", folder);
      formData.append("metadata", JSON.stringify(metadata));

      console.log("FORM_DATA: ", formData);

      const route = `${this.getResource().root}/upload`;
      const { data } = await this.http.post(route, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("StorageService: ", data);
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default StorageService;

import "@pitom84/integrations-services"

// import * as Sentry from "@sentry/vue";
import { VueMaskDirective } from 'v-mask'
import Vue from 'vue'

import App from './App.vue'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import './components/global'
import './plugins'
import './prototype'
import './vue/filters'
import './vue/mixins'

import Authentication from "@pitom84/integrations-services/src/classes/authentication/Authentication"

Vue.config.productionTip = false

Vue.directive('mask', VueMaskDirective);

// const user = localStorage.getItem("user")
// if (user) {
//   Sentry.setUser({ email: JSON.parse(user).email })
// }

// Sentry.init({
//   Vue,
//   dsn: "https://886705b77de14c8d84a6271c17b8e4dd@o4505025578074112.ingest.sentry.io/4505026500231168",
//   trackComponents: true,
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       // tracePropagationTargets: ["app.pitom.com.br","api.pitom.com.br", /^\//],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

let vueInstance = null

Authentication.onAuthStateChanged(() => {
  if (!vueInstance) {
    vueInstance = new Vue({
      vuetify,
      store,
      router,
      i18n,
      // VueMask,
      render: h => h(App)
    }).$mount('#app')
  }
})

let data = {}

export  function useDataAvaliacao() {
    function resetAll() {
        data = {};
    }

    function setStoreUser(store_user) {
        data.store_user = store_user;
    }


    function getStoreUser(){
        return data.store_user;
    }
    
    function setClient(client) {
        data.client = client;
    }

    function getClient() {
        return data.client;
    }

    function setChecktudoData(checktudo) {
        data.checktudo = checktudo
    }

    function getChecktudoData() {
        return data.checktudo;
    }

    return {
        resetAll,
        setClient,
        getClient,
        setStoreUser,
        getStoreUser,
        setChecktudoData,
        getChecktudoData
    }
}
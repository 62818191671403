import { Service } from "../../config/service";

class NotificationService extends Service {

  constructor() {
    super("notifications")
  }

}

export default NotificationService

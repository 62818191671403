import { Service } from "../../config/service";

class FipeVehiclesService extends Service {

  constructor() {
    super("fipe/vehicles")
  }

}

export default FipeVehiclesService

import { Service } from "../../../config/service";

class ExportFileService extends Service {
  constructor() {
    super("report/export/file");
  }

  async fetch_PITOM84_EXPORT_STORE_SELLER_STATUS(
    type,
    store_name,
    { store_id, team_id, user_id, p_inputdate_init, p_inputdate_last }
  ) {
    try {
      const ignorepagination = type == "xls" ? true : false;
      this.setParam("type", type);
      this.setParam("report", "status_appraisal_report");
      this.setParam("param", {
        ignorePagination: ignorepagination,
        onePagePerSheet: false,
        p_store_id: store_id,
        p_team_id: team_id,
        p_user_id: user_id,
        dateB: p_inputdate_init,
        dateD: p_inputdate_last,
      });
      // { store_id: 319, p_inputdate_init: "2023-01-01", p_inputdate_last: "2023-01-27" }

      const { data } = await this.list({
        responseType: "blob",
      });

      const file = new Blob([data], { type: `application/${type}` });
      const fileURL = URL.createObjectURL(file);
      // window.open(fileURL)

      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", `${store_name}.${type}`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e);
      this.$toast.error(e.message);
    } finally {
      this.loading = false;
    }
  }

  async fetchReport(type, reportName, file_name, params) {
    try {
      const ignorepagination = type == "xls" ? true : false;
      this.setParam("type", type);
      this.setParam("report", reportName);
      this.setParam("param", {
        ignorePagination: ignorepagination,
        onePagePerSheet: false,
        ...params
      });
      // { store_id: 319, p_inputdate_init: "2023-01-01", p_inputdate_last: "2023-01-27" }

      const { data } = await this.list({
        responseType: "blob",
      });
      const file = new Blob([data], { type: `application/${type}` });
      const fileURL = URL.createObjectURL(file);
      // window.open(fileURL)

      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", `${file_name}.${type}`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e);
      this.$toast.error(e.message);
    } finally {
      this.loading = false;
    }
  }

  async fetch_PITOM84_EXPORT_CLIENT_XLS(
    type,
    store_name,
    { store_id, team_id, user_id, p_inputdate_init, p_inputdate_last }
  ) {
    try {
      const ignorepagination = type == "xls" ? true : false;
      this.setParam("type", type);
      this.setParam("report", "PITOM84_EXPORT_CLIENT_XLS");
      this.setParam("param", {
        ignorePagination: ignorepagination,
        onePagePerSheet: false,
        store_id: store_id,
        team_id: team_id,
        databegin: p_inputdate_init,
        datadone: p_inputdate_last,
      });
      // { store_id: 319, p_inputdate_init: "2023-01-01", p_inputdate_last: "2023-01-27" }

      const { data } = await this.list({
        responseType: "blob",
      });

      const file = new Blob([data], { type: `application/${type}` });
      const fileURL = URL.createObjectURL(file);
      // window.open(fileURL)

      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", `${store_name}.${type}`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e);
      this.$toast.error(e.message);
    } finally {
      this.loading = false;
    }
  }

  async fetch_PITOM84_EXPORT_CUSTOMERS_APPRAISALS_XLS(type, params) {
    const queryParams = {
      START_DATE: params.startDate,
      END_DATE: params.endDate,
      STORES: params.stores,
      SKETCH: params.sketch,
      STATUS: params.status,
    };

    this.setParam("type", type);
    this.setParam("report", "PITOM84_EXPORT_CUSTOMERS_APPRAISALS_XLS");
    this.setParam("param", queryParams);

    const { data } = await this.list({
      responseType: "blob",
    });

    const file = new Blob([data], { type: `application/${type}` });
    const fileUrl = URL.createObjectURL(file);

    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = `Relatório de Clientes.${type}`;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(fileUrl);
    link.remove();
  }
}

export default ExportFileService;

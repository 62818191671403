import Authentication from "@pitom84/integrations-services/src/classes/authentication/Authentication"

export default function auth({ next, router }) {
  if (!Authentication.isAuthenticated()) {
    localStorage.removeItem("user")
    return router.push({ name: "Login" })
  }

  if (!localStorage.getItem("user")) {
    if (Authentication.isAuthenticated())
      Authentication.signOut()
    return router.push({ name: "Login" })
  }

  return next()
}

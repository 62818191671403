import { Service } from "../../config/service";

class MailService extends Service {

    constructor() {
        super("mail")
    }

    async sendOne(email, email_oculto, service, assunto, mensagem) {
        try {
            this.getResource().insert += "/send"
            const response = await this.insert({
                email: email,
                email_oculto: email_oculto,
                service: service,
                assunto: assunto,
                mensagem: mensagem
            })
            return Promise.resolve(response)
        } catch (e) {
            console.error(e)
            return Promise.reject(e)
        }
    }

    async sendFormattedEmail(dadosEmail, emailPath) {
        try {
            this.getResource().insert += emailPath;
            const response = await this.insert(dadosEmail);
            return Promise.resolve(response);
        } catch (e) {
            console.error(e);
            return Promise.reject(e);
        }
    }

}

export default MailService
import { Service } from "../../../config/service";

class PluginFipeBrandService extends Service {

  constructor() {
    super("plugin/fipe/brand")
  }

}

export default PluginFipeBrandService

import { Service } from "../../config/service";

class InviteService extends Service {

  constructor() {
    super("report")
  }

}

export default InviteService

import { Service } from "../../config/service";

class StoreService extends Service {

  constructor() {
    super("business")
  }

}

export default StoreService

import Authentication from '@pitom84/integrations-services/src/classes/authentication/Authentication';

export default {
  async config(config) {

    if (!Authentication.isAuthenticated())
      return config

    if (!Authentication.tokenIsValid())
      await Authentication.refreshToken()

    const token = Authentication.getToken()

    if(config.url.includes("undefined")){
      return Promise.reject("Requisição com valor undefined");
    }

    config.headers['Authorization'] = `Bearer ${token}`
    return config
  },

  error(error) {
    console.log("Interceptor Request Error: ", error);
    return Promise.reject(error);
  },
};

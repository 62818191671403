import Vue from 'vue'

Vue.mixin({
  methods: {
    _can(action) {
      if(this.$ability.can('full-access'))
        return true
      
      return this.$ability.can(action)
    },

    _isSudo() {
      return this.$ability.can('full-access')
    }
  }
})

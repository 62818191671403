<template>
  <v-dialog v-bind="$attrs" v-model="dialog" persistent transition="fade-transition" max-width="1000px"
    max-height="1000px">
    <v-card>
      <v-toolbar color="transparent" elevation="0">
        <span v-show="!zoom"> Clique na imagem para ativar zoom </span>
        <v-btn v-show="zoom" small color="primary2" class="white--text" @click="zoom = false">
          Desativar zoom
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn small color="error" @click="dialog = false">
          <v-icon left>mdi-close</v-icon>
          Fechar
        </v-btn>
      </v-toolbar>

      <zoom-on-hover v-show="zoom" :img-normal="image"></zoom-on-hover>

      <v-img v-show="!zoom" :src="image" @click="zoom = true"> </v-img>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ZoomImage",

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    image: {
      required: true,
    },
  },

  data() {
    return {
      zoom: false,
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
        if (!v) this.zoom = false;
      },
    },
  },

  methods: {
    onClick() {
      alert("click");
    },
  },
};
</script>

import { Service } from "../../config/service";

class LimitBidsService extends Service {

  constructor() {
    super("limit-bids")
  }

  async getLimitPorMarca(marca_id) {
    try{
      const url = `${this.getResource().root}/getByMarca/${marca_id}`;
      const response = this.http.get(url)
      return response;
    } catch(e) {
      console.error(e);
    }
  }

}

export default LimitBidsService
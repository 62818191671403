import { Service } from "../../config/service";

class RolesService extends Service {

  constructor() {
    super("roles")
  }

}

export default RolesService

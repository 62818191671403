import './style.scss'

import i18n from '../../i18n'
import Vue from 'vue'
import Swal from 'sweetalert2'

class SwalMethods {
  constructor() {
    this.onYesCallback = null
    this.onNoCallback = null
  }

  Swal() {
    return Swal 
  }

  message(text, title, icon) {
    Swal.fire({
      title,
      html: text,
      icon
    })
  }

  info(text, title = i18n.t('info')) {
    this.message(text, title, 'info')
  }

  success(text, title = i18n.t('success.success')) {
    this.message(text, title, 'success')
  }

  warning(text, title = i18n.t('warning')) {
    this.message(text, title, 'warning')
  }

  error(text, title = i18n.t('error')) {
    this.message(text, title, 'error')
  }

  confirm(text, title = i18n.t('confirmation'), options = {}) {
    Swal.fire({
      title,
      html: text,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: `<i aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-check-circle theme--light swal-icon-yes"></i> ${i18n.t('yes')}`,
      cancelButtonText: `<i aria-hidden="true" class="v-icon notranslate v-icon--left mdi mdi-close-circle theme--light swal-icon-no"></i> ${i18n.t('no')}`,
      allowOutsideClick: false,
      ...options
    }).then(result => {
      if(result.isConfirmed) {
        if(this.onYesCallback && typeof this.onYesCallback === 'function') {
          this.onYesCallback(result)
        }
      } else {
        if(this.onNoCallback && typeof this.onNoCallback === 'function') {
          this.onNoCallback(result)
        }
      }

      this._reset()
    })
    return this
  }

  onYes(callback) {
    this.onYesCallback = null
    this.onYesCallback = callback
    return this
  }

  onNo(callback) {
    this.onNoCallback = null
    this.onNoCallback = callback
    return this
  }

  _reset() {
    this.onYesCallback = null
    this.onNoCallback = null
  }
}

Vue.prototype.$swal = new SwalMethods()

export default new SwalMethods()

import { Service } from "../../config/service";

class ClientesService extends Service {

  constructor() {
    super("owner")
  }

}

export default ClientesService

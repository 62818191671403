import { AuthMiddleware, GuestMiddleware } from "@pitom84/middlewares";
import { getAnalytics, logEvent } from "firebase/analytics";
import Vue from "vue";
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@pitom84/views/src/auth/Login.vue"),
    meta: {
      layout: "blank",
      middleware: [GuestMiddleware],
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@pitom84/views/src/auth/ForgotPassword.vue"),
    meta: {
      layout: "blank",
      middleware: [GuestMiddleware]
    }
  },
  {
    path: "/invite/:p_invite",
    name: "Invite",
    component: () => import("@pitom84/views/src/usuarios/UsuarioAdd.vue"),
    meta: {
      layout: "blank",
      middleware: [GuestMiddleware],
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@pitom84/views/src/auth/Logout.vue"),
    meta: {
      layout: "blank",
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@pitom84/views/src/dashboard/Dashboard.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/avaliacoes",
    name: "Avaliações",
    component: () => import("@pitom84/views/src/avaliacoes/AvaliacoesList.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/avaliacao/:p_avaliacao",
    name: "Avaliação",
    props: true,
    component: () => import("@pitom84/views/src/avaliacao/Avaliacao.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/stores",
    name: "Concessionárias",
    component: () => import("@pitom84/views/src/store/StoreList.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/repasse",
    name: "Repasse",
    component: () => import("@pitom84/views/src/repasse/RepasseList.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: '/minhas-propostas',
    name: 'Minhas Propostas',
    component: () => import('@pitom84/views/src/minhas-propostas/MinhasPropostas.vue'),
  },
  {
    path: "/repasse/:p_repasse",
    name: "Repasse-Single",
    props: true,
    component: () => import("@pitom84/views/src/repasse-single/Repasse.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    component: () =>
      import("@pitom84/views/src/marketplace/MarketplaceList.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/clientes",
    name: "Clientes",
    component: () => import("@pitom84/views/src/clientes/ClienteList.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/relatorios",
    name: "Relatorios",
    component: () => import("@pitom84/views/src/relatorios/Relatorios.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/grupos",
    name: "Grupos",
    component: () => import("@pitom84/views/src/grupos/Grupos.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/notificacoes",
    name: "Notificacoes",
    component: () => import("@pitom84/views/src/notificacoes/Notificacoes.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: () => import("@pitom84/views/src/usuarios/UsuariosList.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/equipes",
    name: "Equipes",
    component: () => import("@pitom84/views/src/equipes/EquipesList.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/veiculos",
    name: "Veiculos",
    component: () => import("@pitom84/views/src/veiculos/VeiculosList.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/invite",
    name: "Convite",
    component: () => import("@pitom84/views/src/invite/InviteList.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/link-externo",
    name: "Link Externo",
    component: () =>
      //import("../components/global/alert-files/maintenance.vue"),
      import("@pitom84/views/src/external-link/ExternalLinkList.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/externo/avaliacao/:hash",
    name: "ExternoAvaliacao",
    component: () =>
      //import("../components/global/alert-files/maintenance.vue"),
      import("@pitom84/views/src/external/Avaliacao/AvaliacaoForm.vue"),
    meta: {
      layout: "blank",
      middleware: [GuestMiddleware]
    },
  },
  {
    path: '/tabela-pitom',
    name: 'Tabela Pitom',
    component: () => import("@pitom84/views/src/pitom-table/PitomTable.vue"),
    meta: {
      middleware: [AuthMiddleware]
    }
  },
  {
    path: '/meu-perfil',
    name: 'Meu Perfil',
    component: () => import('@pitom84/views/src/meu-perfil/MeuPerfil.vue'),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/tipos-notificacao",
    name: "Tipos de Notificacao",
    component: () => import("@pitom84/views/src/notification-types/NotificationTypes.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/limitar-lances",
    name: "Limitar Lances",
    component: () => import("@pitom84/views/src/limitar-lances/LimitarLances.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function nextMiddlewareCheck(context, middleware, index) {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMidd = nextMiddlewareCheck(context, middleware, index + 1);

    nextMiddleware({ ...context, next: nextMidd });
  };
}

router.beforeEach((to, from, next) => {
  const analytics = getAnalytics();
  logEvent(analytics, "page_view", {
    page_location: to.fullPath,
    page_path: to.path,
    page_title: to.name,
    screen_name: to.name,
  });

  logEvent(analytics, "screen_view", {
    page_path: to.path,
    page_title: to.name,
    screen_name: to.name,
  });

  // Middlewares
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const ctx = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextMiddlewareCheck(ctx, middleware, 1);
    return middleware[0]({ ...ctx, next: nextMiddleware });
  }
  return next();
});

export default router;

import { Service } from "../../../config/service";

class PluginFipeYearService extends Service {

  constructor(brand, model) {
    super(`plugin/fipe/year/${brand}/${model}`)
  }

}

export default PluginFipeYearService

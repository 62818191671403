import { Service } from "../../../config/service";

class PluginFipeVehicleService extends Service {

  constructor(brand, model, year) {
    super(`plugin/fipe/vehicle/${brand}/${model}/${year}`)
  }

}

export default PluginFipeVehicleService

Date.prototype.formatStringDate = function (textDate) {
  const separateDate = textDate.split("T");
  const formattedDate = separateDate[0].split("-").reverse().join("/");
  const formattedTime = separateDate[1].split(".")[0];
  const completedDateString = formattedDate + " " + formattedTime;
  return completedDateString;
};

Date.prototype.saoPauloFormatter = function () {
  const s = new Intl.DateTimeFormat("pt-BR", {
    timeZone: "America/Sao_Paulo",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return s.format(this);
};

Date.prototype.getCorrectDate = function (textDate) {
  const date = textDate.split("Z")[0];
  const formattedDate = new Date(date);

  return formattedDate;
};

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + Number(h.replace("h", "")) * 60 * 60 * 1000);
  return this;
};

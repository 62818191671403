import { Service } from "../../config/service";

class MyProposalsBidsService extends Service {

  constructor() {
    super("my-proposals/bids")
  }
  async listLancesRecebidos(page, status){
    try{
      this.getResource().list += "/recebidos";
      this.setParam("page", page);
      this.setParam("status", status);

      const { data } = await this.list();
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
}

export default MyProposalsBidsService

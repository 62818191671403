import { Service } from "../../config/service";

class PrecoPitom extends Service {

  constructor() {
    super("pricepitom", "")
  }

  async getPrice(brand, model, year) {
    try {
        // this.getResource().get; // += `/${brand}/${model}/${year}`
        this.setParam('pricepitom', [{ "brand": brand, "model": model, "year": year }])
        // console.log(this.getResource().get)
        const { data } = await this.list()
        return data
    } catch (error) {
        return false
    }
  }

  async fetchPrices(monthYear, params = {}) {
    try {
      const queryParams = new URLSearchParams(params).toString()
      const response = await this.http.get(`${this.getResource().root}/${monthYear}?${queryParams}`)
      return response
    } catch(error) {
      Promise.reject(error)
    }
  }

  async fetchAppraisals(brand, model, year, page = 1) {
    try {
      const query = `?brand=${brand}&model=${model}&year=${year}&page=${page}`
      const response = await this.http.get(`${this.getResource().root}/appraisals/vehicles/${query}`)
      return response
    } catch(error) {
      Promise.reject(error)
    }
  }

  async processPitomPrice(){
    try {
      await this.http.post(`${this.getResource().root}/process-data`)
      Promise.resolve()
    } catch(error) {
      Promise.reject(error)
    }
  }

  async checkHasProcessPitomPrice(monthYear) {
    try {
      const result = await this.http.get(`${this.getResource().root}/process-data/${monthYear}`)
      return result
    } catch(error) {
      Promise.reject(error)
    }
  }

  async deletePitemPrice(monthYear) {
    try {
      const result = await this.http.delete(`${this.getResource().root}/process-data/${monthYear}`)
      return result
    } catch(error) {
      Promise.reject(error)
    } 
  }
}

export default PrecoPitom
import { AbilityBuilder } from "@casl/ability";
import RoleRules from "./role-rules";

export default function defineRulesFor(role) {
  const { can, rules } = new AbilityBuilder();
  const roleRules = RoleRules[role];
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user.store_users[0]) {
    can("no-access");
    return rules;
  }
  if (roleRules) {
    for (let current of roleRules) {
      if (current?.action) can(current.action);
    }
  } else {
    can("no-access");
  }

  return rules;
}

import { Service } from "../../config/service";

class UserService extends Service {
  constructor(id, uri) {
    const ur = uri ? uri : "users";
    super(ur, id);
  }

  async getByIdOrEmail(id, email) {
    try {
      const response = await this.http.get(
        `${this.getResource().get}/${id}?email=${email}`
      );
      return Promise.resolve(response);
    } catch (e) {
      this.setError(e);
      return Promise.reject(this.getError());
    }
  }
}

export default UserService;

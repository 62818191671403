import CustomError from "../../../src/classes/errors/CustomError";

const errors = {
  execute(code) {
    const error = errors[code]
    if(error) return error()
    return errors.default()
  },

  "auth/user-not-found": () => { throw new CustomError("E-mail ou senha está incorreto.") },
  "auth/wrong-password": () => { throw new CustomError("E-mail ou senha está incorreto.") },
  "auth/invalid-email-verified": () => { throw new CustomError("Seu e-mail ainda não foi validado, verifique a caixa de entrada.", "warning") },
  "auth/too-many-requests": () => { throw new CustomError("Muitas tentativas de login, tente novamente em alguns minutos.", "warning") },
  
  default: () => { throw new CustomError("Não foi possível fazer login, tente novamente ou contacte o administrador.") }
}

export default function handleSignInError(error) {
  return errors.execute(error.code)
}

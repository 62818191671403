import { Service } from "../../config/service";

class MessageTypesRoleService extends Service {

  constructor() {
    super("message-types/join/role")
  }

}

export default MessageTypesRoleService

import Vue from "vue";

import i18n from "../../i18n";

Vue.filter("currency", function (value) {
  if (isNaN(value)) return value;

  if (typeof value !== "number") value = parseFloat(value);

  return i18n.n(value, "currency", "BR", { useGrouping: false });
});

Vue.filter("currencySymbol", function (value) {
  if(!value) return value
  if (isNaN(value)) return value;

  if (typeof value !== "number") value = parseFloat(value);

  return value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
});

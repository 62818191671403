<template>
  <vuetify-money
    v-bind="$attrs"
    v-model="computedValue"
    :options="options"
  ></vuetify-money>
</template>

<script>
export default {
  name: 'MyTextMoney',
  props: ['value'],

  data() {
    return {
      options: {
        locale: 'pt-BR',
        prefix: 'R$',
        suffix: '',
        length: 8,
        precision: 0
      }
    }
  },

  computed: {
    computedValue: {
      get() { return this.value },
      set(e) { this.$emit('input', e) }
    }
  }
}
</script>
import { Service } from "../../config/service";

class ExternalLink extends Service {

  constructor(id) {
    super('external-link', id)
  }

}

export default ExternalLink

import { Service } from "../../config/service";

class UserMessageService extends Service {

  constructor() {
    super("message-types/join/user")
  }

}

export default UserMessageService

import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent } from "firebase/analytics"
import firebaseConfig from "./config"

export default class Firebase {
  static initialize() {
    const app = initializeApp(firebaseConfig)
    const analytics = getAnalytics(app)
    logEvent(analytics, "notification_received")
  }
}

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: {
      show: false,
      message: ''
    },

    user: JSON.parse(localStorage.getItem('user')),
    
    newAvaliacao: {
      storeName: ''
    }
  },

  getters: {
    loading: ({ loading }) => loading,
    user: ({ user }) => user,
    newAvaliacao: ({ newAvaliacao }) => newAvaliacao
  },

  mutations: {
    setLoading(state, payload) {
      state.loading = payload
    },

    setUser(state, payload) {
      state.user = payload
    },

    setNewAvaliacao(state, { storeName }) {
      state.newAvaliacao.storeName = storeName
    }
  },

  actions: {
    setLoading({ commit }, payload) {
      commit('setLoading', payload)
    },

    setUser({ commit }, payload) {
      commit('setUser', payload)
    },

    setNewAvaliacao({ commit }, payload) {
      commit('setNewAvaliacao', payload)
    }
  },

  modules: {
  }
})

<template>
  <v-overlay
    z-index="9999"
    :value="loading.show"
  >
    <v-card
      class="card rounded-10 box-shadow"
      :class="`card-${$vuetify.theme.dark ? 'dark' : 'light'}`"
    >
      <v-card-text>
        <div class="text">
          {{ loading.message }}
        </div>

        <div class="progress">
          <v-progress-circular
            indeterminate
          ></v-progress-circular>
        </div>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>
import './index'

export default {
  name: 'TheLoading',

  computed: {
    loading() { return this.$store.getters['loading'] }
  }
}
</script>

<style lang="scss" scoped>
.card {
  padding: 25px;
  width: 300px;
  min-width: 300px;
  max-width: 300px;

  .text {
    text-align: center;
    font-weight: bold;
    font-size: 12pt;
  }

  .progress {
    text-align: center;
    margin-top: 25px;
  }
}

.card-dark {
  background-color: rgb(230, 230, 230);

  .text {
    color: rgba(0, 0, 0, 0.7);
  }

  .progress {
    color: rgba(0, 0, 0, 0.7);
  }
}
</style>

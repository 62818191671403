import { Service } from "../../config/service";

class AppraisalsService extends Service {
  constructor(id) {
    super("appraisals", id);
  }

  async _saveLog(metaData, newValue) {
    const u = JSON.parse(localStorage.getItem("user"));
    const obj = {
      appraisal_id: this.getId(),
      user_store_id: u.store_users[0].id,
      metadata: metaData,
      newvalue: newValue,
    };
    try {
      this.getResource().insert += "/join/logs";
      await this.insert(obj);
    } catch (e) {
      console.error(e);
    } finally {
    }
  }

  async _getLog() {
    try {
      this.getResource().get += "/join/logs";
      this.setParam("like", [
        { search: this.getId(), search_type: "appraisal_id" },
      ]);
      const { data } = await this.get();
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  async getDuplicateAppraisals() {
    try {
      this.getResource().get += "/card/";
      const response = await this.http.get(
        this.getResource().get + this.getId(),
        { params: this.getParams() }
      );
      return Promise.resolve(response);
    } catch (e) {
      console.error(e);
    }
  }

  async getExistingSketch(user_id, phone, license_plate) {
    try {
      const url = `${
        this.getResource().root
      }/existing-sketch/${user_id}/${phone}/${license_plate}`;
      const response = this.http.get(url);
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  async getAppraisalsGage(id_store) {
    try {
      const url = `${this.getResource().root}/join/appraisal_gage/${id_store}`;
      const response = await this.http.get(url);
      return Promise.resolve(response);
    } catch (e) {
      console.error(e);
    }
  }

  async listAppraisalGageValues(id_appraisal) {
    try {
      const url = `${
        this.getResource().root
      }/join/appraisal_gage/listValues/${id_appraisal}`;
      const response = await this.http.get(url);
      return Promise.resolve(response);
    } catch (e) {
      console.error(e);
    }
  }

  async saveAppraisalGageValues(body) {
    try {
      if (!this.getResource().insert.includes("/join/appraisal_gage")) {
        this.getResource().insert += `/join/appraisal_gage`;
      }
      const response = await this.insert(body);
      return Promise.resolve(response);
    } catch (e) {
      console.error(e);
    }
  }

  async updateAppraisalGageValue(body) {
    try {
      this.getResource().update += "/join/appraisal_gage";
      const response = await this.update(body);
      return Promise.resolve(response);
    } catch (e) {
      console.error(e);
    }
  }
}

export default AppraisalsService;

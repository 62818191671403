import FirebaseAuth from "../../../firebase/auth"

const services = {
  firebase: FirebaseAuth
}

export default class AuthenticationFactory {
  static getAuthentication() {
    const useService = process.env.VUE_APP_USE_SERVICE || 'firebase'
    if (!services[useService]) {
      console.error("Serviço de autenticação inválido.")
      throw new Error("Serviço de autenticação inválido.")
    }

    return new services[useService]
  }
}

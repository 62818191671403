//https://vee-validate.logaretm.com/v4/

import Vue from 'vue'
import  {
  required,
  email,
  max,
  min,
  size,
  oneOf,
  numeric
} from 'vee-validate/dist/rules'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from 'vee-validate'
import i18n from '../i18n'
import cpfValidation from '@/helpers/cpf-validation'
import placaValidation from '@/helpers/placa-validation'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: i18n.t('veevalidate.required')
})

extend('oneof', {
  ...oneOf
})

extend('max', {
  ...max,
  message: i18n.t('veevalidate.max')
})

extend('min', {
  ...min,
  message: i18n.t('veevalidate.min')
})

extend('email', {
  ...email,
  message: i18n.t('veevalidate.email')
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: i18n.t('veevalidate.password')
})

extend('size', {
  ...size,
  message: i18n.t('veevalidate.size')
})

extend('numeric', {
  ...numeric,
  message: i18n.t('veevalidate.numeric')
})

extend('not_zero', {
  validate(value) {
    if(value == 0) return false
    return true
  },
  message: '{_field_} não pode ser zero'
})

extend('placa', {
  validate(value) {
    return placaValidation(value)
  },
  message: 'Placa não é válida'
})

/* CPF */
extend('cpf', {
  validate(value) {
    if(value.length == 14) {
      return cpfValidation(value)
    }
    return false
  },
  message: 'CPF não é válido'
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

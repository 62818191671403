export default class IAuthAdapter {
  async signInWithEmailAndPassword(email, password) {}
  async sendEmailVerification() {}
  async sendPasswordResetEmail(email) {}
  async signOut() {}
  async refreshToken() {}
  getCurrentUser() {}
  tokenIsValid() {}
  getToken() {}
  emailIsVerified() {}
  isAuthenticated() {}
  onAuthStateChanged(callback) { callback(/** AuthUserDTO | null */ null) }
}

import IAuthAdapter from "../../src/classes/interfaces/IAuthAdapter";
import AuthUserDTO from "../../src/classes/authentication/AuthUserDTO";
import { handleSignInError, handleSendPasswordResetEmail } from "./errors";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, onAuthStateChanged } from "firebase/auth";

export default class FirebaseAuth extends IAuthAdapter {

  constructor() {
    super()
    this._auth = getAuth()
  }

  async signInWithEmailAndPassword(email, password) {
    try {
      return await signInWithEmailAndPassword(this._auth, email, password)
    } catch (error) {
      throw handleSignInError(error)
    }
  }

  async sendEmailVerification() {
    try {
      return await sendEmailVerification(this._auth.currentUser)
    } catch (error) {
      throw new Error("Não foi possível enviar o email de verificação.")
    }
  }

  async sendPasswordResetEmail(email) {
    try {
      return await sendPasswordResetEmail(this._auth, email)
    } catch (error) {
      throw handleSendPasswordResetEmail(error)
    }
  }

  async signOut() {
    return await this._auth.signOut()
  }

  async refreshToken() {
    const token = await this._auth.currentUser.getIdToken(true)
    return token
  }

  getCurrentUser() {
    if (!this.isAuthenticated())
      return null

    return new AuthUserDTO(
      this._auth.currentUser.uid,
      this._auth.currentUser.displayName,
      this._auth.currentUser.email,
      this._auth.currentUser.emailVerified,
      this._auth.currentUser.accessToken
    )
  }

  tokenIsValid() {
    const expirationTime = this._auth.currentUser.stsTokenManager.expirationTime
    return new Date(expirationTime) > new Date()
  }

  getToken() {
    const token = this._auth.currentUser.accessToken
    return token
  }

  emailIsVerified() {
    return !!this._auth.currentUser.emailVerified
  }

  isAuthenticated() {
    return !!this._auth.currentUser
  }

  onAuthStateChanged(callback) {
    onAuthStateChanged(this._auth, (user) => {
      if(!user) return callback(null)
      callback(new AuthUserDTO(
        user.uid,
        user.displayName,
        user.email,
        user.emailVerified,
        user.accessToken
      ))
    })
  }
}

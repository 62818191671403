import CustomError from "../../../src/classes/errors/CustomError";

const errors = {
  execute(code) {
    const error = errors[code]
    if(error) return error()
    return errors.default()
  },

  "auth/user-not-found": () => { throw new CustomError("E-mail não foi encontrado.", "warning") },
  
  default: () => { throw new CustomError("Não foi possível enviar o email para recuperação de senha.") }
}

export default function handleSignInError(error) {
  return errors.execute(error.code)
}

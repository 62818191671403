import AuthenticationFactory from "./AuthenticationFactory"

class Authentication {
  constructor() {
    this._authProvider = AuthenticationFactory.getAuthentication()
  }

  async signInWithEmailAndPassword(email, password) {
    return await this._authProvider.signInWithEmailAndPassword(email, password)
  }

  async sendEmailVerification() {
    return await this._authProvider.sendEmailVerification()
  }

  async sendPasswordResetEmail(email) {
    return await this._authProvider.sendPasswordResetEmail(email)
  }

  async signOut() {
    return await this._authProvider.signOut()
  }

  async refreshToken() {
    return await this._authProvider.refreshToken()
  }

  getCurrentUser() {
    return this._authProvider.getCurrentUser()
  }

  tokenIsValid() {
    return this._authProvider.tokenIsValid()
  }

  getToken() {
    return this._authProvider.getToken()
  }

  emailIsVerified() {
    return this._authProvider.emailIsVerified()
  }

  isAuthenticated() {
    return this._authProvider.isAuthenticated()
  }

  onAuthStateChanged(callback) {
    this._authProvider.onAuthStateChanged(callback) 
  }
}

export default new Authentication

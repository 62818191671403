import { Service } from "../../config/service";

class ImagesService extends Service {

  constructor(id) {
    super("ai/images", id)
  }

}

export default ImagesService

import { Service } from "../../config/service";

class InviteService extends Service {

  constructor() {
    super("invite")
  }

}

export default InviteService

import { Service } from "../../config/service";

class EquipesService extends Service {

  constructor(id = null) {
    super("teams", id)
  }

}

export default EquipesService

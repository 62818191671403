import { Service } from "../../../config/service";

class DashboardService extends Service {

  constructor() {
    super("report/dashboard")
  }

  async fetchStatus(dateDays) {
    try {
      const url = `${this.getResource().root}/status/${dateDays}`
      const response = await this.http.get(url)
      return Promise.resolve(response)
    } catch(e) {
      console.error(e)
      return Promise.reject(e)
    }
  }

  async fetchPerdido() {
    try {
      const url = `${this.getResource().root}/perdido`
      const response = await this.http.get(url)
      return Promise.resolve(response)
    } catch(e) {
      console.error(e)
      return Promise.reject(e)
    }
  }
}

export default DashboardService

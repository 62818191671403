import ServiceModel from "./service-model.class.js";

export default class Service extends ServiceModel {

  constructor(resource, id, relationship = null) {
    super()
    this.setResource(resource)
    this.setId(id)
    this.setRelationship(relationship)
    //this.boot()
  }

  boot() {
    this.generateListRelationship()
    this.generateGetRelationship()
    this.generateUpdateRelationship()
    this.generateDeleteRelationship()
    this.generateInsertRelationship()
  }

  async list(config = {})
  {
    try {
      const response = await this.http.get(this.getResource().list, { params: this.getParams(), ...config })
      return Promise.resolve(response)
    } catch(e) {
      this.setError(e)
      return Promise.reject(this.getError())
    }
  }

  async get(config = {}) {
    try {
      const response = await this.http.get(`${this.getResource().get}/${this.getId()}`, config)
      return Promise.resolve(response)
    } catch(e) {
      this.setError(e)
      return Promise.reject(this.getError())
    }
  }

  async insert(data, config = {}) {
    try {
      const response = await this.http.post(this.getResource().insert, data, config)
      return Promise.resolve(response)
    } catch(e) {
      this.setError(e)
      return Promise.reject(this.getError())
    }
  }

  async update(data, config = {}) {
    try {
      const response = await this.http.put(`${this.getResource().update}/${this.getId()}`, data, config)
      return Promise.resolve(response)
    } catch(e) {
      this.setError(e)
      return Promise.reject(this.getError())
    }
  }

  async delete(config = {}) {
    try {
      const response = await this.http.delete(`${this.getResource().delete}/${this.getId()}`, config)
      return Promise.resolve(response)
    } catch(e) {
      this.setError(e)
      return Promise.reject(this.getError())
    }
  }

  generateListRelationship() {
    for (const key in this.getRelationship()) {
      this[key + 'List'] = params => this.http.get(`${this.getResource()}/${this.getId()}/${key}`,
        { params: { ...params } })
    }
  }

  generateGetRelationship() {
    for (const key in this.getRelationship()) {
      this[key + 'Get'] = (id) => this.http.get(`${this.getResource()}/${this.getId()}/${key}/${id}`)
    }
  }

  generateDeleteRelationship() {
    for (const key in this.getRelationship()) {
      this[key + 'Delete'] = (id) =>
        this.http.delete(`${this.getResource()}/${this.getId()}/${key}/${id}`)
    }
  }

  generateUpdateRelationship() {
    for (const key in this.getRelationship()) {
      this[key + 'Update'] = (id, data) =>
        this.http.update(`${this.getResource()}/${this.getId()}/${key}/${id}`, data)
    }
  }

  generateInsertRelationship() {
    for (const key in this.getRelationship()) {
      this[key + 'Insert'] = (data) =>
        this.http.post(`${this.getResource()}/${this.getId()}/${key}`, data)
    }
  }

}
